<template>
  <div class="container">
    <div class="row">
      <button
        style="float: right"
        class="btn btn-secondary"
        @click="$router.push({ name: 'invoiceBuilder' })"
      >
        {{ $t("product.back") }}
      </button>
    </div>
    <div class="product-record-container">
      <div class="product-record">
        <div class="input-group search-field">
          <input
            class="form-control form-control-lg"
            v-bind:value="searchText"
            v-on:input="debounceSearch($event.target.value)"
            v-bind:placeholder="$t('product.search')"
          />
          <div class="search-icon">
            <i class="fa fa-search text-muted"></i>
          </div>
        </div>

        <div class="product-flip-table mt-3">
          <v-server-table
            ref="productTable"
            :url="''"
            :columns="columns"
            :options="options"
          >
            <template slot="code" scope="products">
              <div
                class="relative"
                v-if="products.row.productId === editing.productId"
              >
                <input
                  v-model="product.code"
                  class="form-control"
                  v-bind:class="{
                    '': validations.product.code === null,
                    'is-valid': validations.product.code == true,
                    'is-invalid': validations.product.code == false,
                  }"
                />
              </div>
              <div v-else class="edit-field">{{ products.row.code }}</div>
            </template>

            <template slot="name" scope="products">
              <div
                class="relative"
                v-if="products.row.productId === editing.productId"
              >
                <input
                  maxlength="65"
                  v-model="product.name"
                  class="form-control"
                  v-bind:class="{
                    '': validations.product.name === null,
                    'is-valid': validations.product.name == true,
                    'is-invalid': validations.product.name == false,
                  }"
                />
              </div>
              <div v-else class="edit-field">{{ products.row.name }}</div>
            </template>

            <template slot="unit" scope="products">
              <select
                v-if="products.row.productId === editing.productId"
                v-model="product.unit"
              >
                <option
                  v-for="(unit, index) in unitTypes"
                  :key="index"
                  :value="unit"
                >
                  {{ $t("units." + unit) }}
                </option>
              </select>
              <div v-else>{{ $t("units." + products.row.unit) }}</div>
            </template>

            <template slot="unitPrice" scope="products">
              <div
                class="relative"
                v-if="products.row.productId === editing.productId"
              >
                <input
                  v-model="product.unitPrice"
                  class="form-control"
                  v-bind:class="{
                    '': validations.product.unitPrice === null,
                    'is-valid': validations.product.unitPrice == true,
                    'is-invalid': validations.product.unitPrice == false,
                  }"
                />
              </div>
              <div v-else class="edit-field">{{ products.row.unitPrice }}</div>
            </template>

            <template slot="taxPercent" scope="products">
              <select
                v-if="products.row.productId === editing.productId"
                v-model="product.taxPercent"
              >
                <option
                  v-for="(rate, index) in VATRates"
                  :key="index"
                  :value="rate"
                >
                  {{ formatNumber(rate) }}
                </option>
              </select>
              <div v-else>{{ formatNumber(products.row.taxPercent) }} %</div>
            </template>
            <template slot="action" slot-scope="props">
              <div
                class="product-action-cell"
                v-if="props.row.productId === editing.productId"
              >
                <i
                  @click="update"
                  :title="$t('product.save')"
                  class="product-action-cell-icon far fa-save"
                ></i>
                <i
                  @click="cancel"
                  :title="$t('product.cancel')"
                  class="product-action-cell-icon fas fa-ban"
                ></i>
              </div>
              <div class="product-action-cell" v-else>
                <i
                  @click="edit(props.index, props.row)"
                  :title="$t('product.edit')"
                  class="product-action-cell-icon far fa-edit"
                ></i>
                <i
                  @click="deleteAction([props.row])"
                  :title="$t('product.delete')"
                  class="product-action-cell-icon fas fa-trash-alt"
                ></i>
              </div>
            </template>

            <template slot="favorite" scope="props">
              <i
                @click="favoriteProduct(props.row)"
                :title="$t('product.setFavorite')"
                v-bind:class="{
                  'fa fa-star favorite-icon yellow': props.row.isFavorite,
                  'fa fa-star favorite-icon gray': !props.row.isFavorite,
                }"
              ></i>
            </template>
          </v-server-table>
        </div>
        <div id="productActions">
          <div class="row mt-3 mb-3 product-actions">
            <div class="col row">
              <div class="form-group col-lg col-xs-12 mr-2 mb-2">
                <label class="form-label">{{ $t("product.code") }}</label>
                <input
                  class="form-control"
                  v-model.trim="newProduct.code"
                  @keyup.enter="add"
                  :placeholder="$t('product.code')"
                  v-bind:class="{
                    '': validations.newProduct.code === null,
                    'is-valid': validations.newProduct.code === true,
                    'is-invalid': validations.newProduct.code === false,
                  }"
                />
              </div>
              <div class="form-group col-lg col-xs-12 mr-2 mb-2">
                <label class="form-label">{{ $t("product.name") }}</label>
                <input
                  class="form-control"
                  @keyup.enter="add"
                  v-model.trim="newProduct.name"
                  :placeholder="$t('product.name')"
                  v-bind:class="{
                    '': validations.newProduct.name === null,
                    'is-valid': validations.newProduct.name === true,
                    'is-invalid': validations.newProduct.name === false,
                  }"
                />
              </div>
              <div class="form-group col-lg mr-2 mb-2">
                <label class="form-label">{{ $t("product.unit") }}</label>
                <select
                  class="form-control form-control-lg add-unit"
                  v-model.trim="newProduct.unit"
                >
                  <option
                    v-for="(unit, index) in unitTypes"
                    :key="index"
                    :value="unit"
                  >
                    {{ $t("units." + unit) }}
                  </option>
                </select>
              </div>
              <div class="form-group col-lg col-xs-12 mr-2 mb-2">
                <label class="form-label">{{ $t("product.price") }}</label>
                <input
                  class="form-control"
                  @keyup.enter="add"
                  v-model.trim="newProduct.unitPrice"
                  :placeholder="$t('product.price')"
                  v-bind:class="{
                    '': validations.newProduct.unitPrice === null,
                    'is-valid': validations.newProduct.unitPrice === true,
                    'is-invalid': validations.newProduct.unitPrice === false,
                  }"
                />
              </div>
              <div class="form-group col-lg mr-2 mb-2">
                <label class="form-label">{{ $t("product.VAT") }}</label>
                <select
                  class="form-control col-lg col-xs-12 form-control-lg add-unit"
                  v-model="newProduct.taxPercent"
                >
                  <option
                    v-for="(item, index) in VATRates"
                    :key="index"
                    :value="item"
                  >
                    {{ formatNumber(item) }}
                  </option>
                </select>
              </div>
              <div class="col">
                <button
                  :disabled="inserting"
                  @click="add"
                  class="add-product btn btn-success mt-3 mr-2 mb-2 loading-btn"
                >
                  {{ inserting ? "" : $t("product.add") }}
                  <i v-if="inserting" class="fa fa-spinner fa-spin"></i>
                </button>
              </div>
            </div>
            <div class="col-lg-2 pl-0 mr-2 mb-2" style="color: red">
              {{ errorMsg }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RowViewerProductInput from "@/components/common/RowViewerProductInput";
import { validateNumberInput, simpleValidation, formatNumber, getTaxCategories, getFinnishGeneralVatRate, getSwedishGeneralVatRate } from "@/utils";

export default {
  name: "productRecord",
  mounted: function () {
    this.newProduct.taxPercent = this.defaultVatRate();
  },
  data() {
    const that = this;
    return {
      formatNumber,
      inserting: false,
      unitTypes: [
        "piece",
        "hour",
        "day",
        "cm",
        "gram",
        "person",
        "kilogram",
        "month",
        "kilometer",
        "kilowattHour",
        "liter",
        "box",
        "meter",
        "squareMeter",
        "cubicMeter",
        "minute",
        "millimeter",
        "bottle",
        "can",
        "bag",
        "roll",
        "page",
        "sack",
        "series",
        "tube",
        "ton",
        "year",
        "week",
      ],
      uniqueKey: "productId",
      errorMsg: "",
      searchText: "",
      searchTimeout: null,
      editing: { index: null, productId: null },
      newProduct: {
        code: "",
        name: "",
        unitPrice: "",
        unit: "piece",
        taxPercent: "0",
      },
      product: {
        productId: "",
        code: "",
        name: "",
        unitPrice: "",
        unit: "",
        taxPercent: "0",
      },
      validations: {
        newProduct: {
          code: null,
          name: null,
          unitPrice: null,
        },
        product: {
          code: null,
          name: null,
          unitPrice: null,
        },
      },
      selectedProducts: [],
      deletedProducts: [],
      columns: [
        "code",
        "name",
        "unit",
        "unitPrice",
        "taxPercent",
        "action",
        "favorite",
      ],
      options: {
        rowClassCallback: function (row) {
          if (
            that.selectedProducts.find((p) => p.productId === row.productId)
          ) {
            return "table-info";
          }
          if (that.deletedProducts.find((p) => p.productId === row.productId)) {
            return "table-danger";
          }
        },

        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        clientMultiSorting: true,
        compileTemplates: true,
        filterable: "",
        sortable: ["code", "name", "unitPrice"],
        orderBy: {
          column: "name",
          ascending: true,
        },
        columnsClasses: {
          select: "select-col",
          code: "editable code-col",
          name: "editable name-col",
          unit: "editable unit-col",
          unitPrice: "editable unit-price",
          taxPercent: "editable unit-col",
          action: "action-col",
          favorite: "action-col",
        },
        sortIcon: {
          is: "fa-sort",
          base: "fa",
          up: "fa-sort-up",
          down: "fa-sort-down",
        },
        filterByColumn: true,
        texts: {
          count: this.$t("product.table.count"),
          filter: this.$t("product.table.filter"),
          filterPlaceholder: this.$t("product.table.filterPlaceholder"),
          limit: this.$t("product.table.limit"),
          page: this.$t("product.table.page"),
          noResults: this.$t("product.table.noResults"),
          filterBy: this.$t("product.table.filterBy"),
          loading: this.$t("product.table.loading"),
          defaultOption: this.$t("product.table.defaultOptions"),
        },
        requestFunction: (data) => {
          let params = {};
          params.limit = data.limit;
          params.ascending = data.ascending == 1 ? "ASC" : "DESC";
          params.byColumn = "both";
          params.query = this.searchText;
          params.page = data.page;
          params.orderBy =
            data.orderBy === "unitPrice" ? "unit_price" : data.orderBy;
          return this.$store.dispatch("product/getProducts", {
            params,
            token: this.token,
          });
        },
        responseAdapter: (resp) => {
          let data = { data: [], count: 0 };

          data.data = resp.data.map((i) => {
            i.unitPrice = i.unitPrice.replace(".", ",");
            i.taxPercent = String(i.taxPercent).replace(".", ",");
            return i;
          });
          data.count = resp.count;
          return data;
        },
        skin: "table product-table table-bordered table-sm table-striped",
        headings: {
          /*           select: function(h) {
            return h("input", {
              attrs: {
                type: "checkbox",
                id: "selectAllCheckbox"
              },
              on: {
                click: e => {
                  this.selectAll(e.srcElement.checked);
                }
              },
              ref: "selectAllCheckbox"
            });
          }, */
          code: that.$t("product.code"),
          name: that.$t("product.name"),
          unitPrice: that.$t("product.price"),
          unit: that.$t("product.unit"),
          taxPercent: that.$t("product.VAT"),
          action: "",
          favorite: "",
        },
      },
    };
  },
  components: {
    RowViewerProductInput,
  },
  watch: {
    $route(to, from) {
      if (to.name === "productRecord") {
        this.$refs.productTable.getData();
      }
    },
    searchText: function (val) {
      this.$refs.productTable.setPage(1);
    },
    "newProduct.code": function (val) {
      this.validations.newProduct.code = simpleValidation(val);
    },
    "newProduct.name": function (val) {
      this.validations.newProduct.name = simpleValidation(val);
    },
    "newProduct.unitPrice": function (val) {
      if (val) {
        this.validations.newProduct.unitPrice = validateNumberInput(val);
      } else {
        this.validations.newProduct.unitPrice = null;
      }
    },
    "product.code": function (val) {
      this.validations.product.code = val !== "";
    },
    "product.name": function (val) {
      this.validations.product.name = val !== "";
    },
    "product.unitPrice": function (val) {
      if (val) {
        this.validations.product.unitPrice = validateNumberInput(val);
      } else {
        this.validations.product.unitPrice = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "finnishSite",
      "swedishSite"
    ]),
    VATRates: function () {
      // return list of VAT rates for all countries - any of them can be defined for product
      return [...new Set(Object.values(getTaxCategories()).flat())]
          .sort((a, b) => a - b)
          .map((vatRate) => vatRate.toString());
    },
    canSaveProduct: function () {
      return (
        this.validations.product.code &&
        this.validations.product.unitPrice &&
        this.validations.product.name
      );
    },
    userData: function () {
      return this.$store.getters["invoice/getField"]("userData");
    },
    token: function () {
      return this.$store.getters["invoice/getField"]("userData.authToken");
    },
  },
  methods: {
    // default selection for new product
    defaultVatRate: function () {
      let generalVatRate;
      if (this.finnishSite) {
        generalVatRate = getFinnishGeneralVatRate();
      } else {
        generalVatRate = getSwedishGeneralVatRate();
      }
      return generalVatRate.toString();
    },
    headerFilter: function (tString, columnName) {
      return function (h) {
        return h("input", {
          attrs: {
            placeholder: tString,
            class: "header-input",
          },
          on: {
            click: (e) => {
              e.stopPropagation();
            },
            keyup: (e) => {
              if (e.target.value === "") {
                this.filterColumn = "";
                this.filterValue = "";
                this.$refs.productTable.getData();
              }
              if (e.code === "Enter") {
                let value = e.target.value;
                this.filterColumn = this.columnMap[columnName];
                this.filterValue = value;
                /*                 this.$refs.productTable.getData();
                this.$refs.productTable.setPage(1); */
              }
            },
          },
        });
      };
    },
    selectAll(checked) {
      if (checked) {
        this.selectedProducts = this.$refs.productTable.data;
      } else {
        this.selectedProducts = [];
      }
    },
    debounceSearch: function (val) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      var that = this;
      this.searchTimeout = setTimeout(function () {
        that.searchText = val;
      }, 400);
    },
    changeTo(routeComponent) {
      this.$router.push({ name: routeComponent });
    },
    edit: function (index, row) {
      this.editing = { index: index, productId: row.productId };
      Object.keys(this.product).forEach((key) => {
        this.product[key] = row[key];
      });
    },
    add: function () {
      this.inserting = true;
      let valid = true;
      if (this.newProduct.code == "") {
        this.validations.newProduct.code = false;
        valid = false;
      }
      if (this.newProduct.name == "") {
        this.validations.newProduct.name = false;
        valid = false;
      }
      if (!validateNumberInput(this.newProduct.unitPrice)) {
        this.validations.newProduct.unitPrice = false;
        valid = false;
      }
      if (!valid) {
        this.inserting = false;
        return;
      }
      let product = Object.assign({}, this.newProduct);
      product.unitPrice = product.unitPrice.replace(",", ".");
      //  product.IDProduct = product.productId
      this.$store
        .dispatch("product/addProduct", { data: product, token: this.token })
        .then((res) => {
          this.newProduct = {
            code: "",
            name: "",
            unitPrice: "",
            unit: "piece",
            taxPercent: this.defaultVatRate(),
          };
          this.$refs.productTable.getData();
          this.inserting = false;
          this.errorMsg = "";
        })
        .catch((error) => {
          this.inserting = false;
          if (error.body.error === "Duplicate product") {
            this.errorMsg = this.$t("product.duplicateProduct");
          }
        });
    },
    update: function (row) {
      if (!this.canSaveProduct) {
        return;
      }
      let product = Object.assign({}, this.product);
      product.unitPrice = product.unitPrice.replace(",", ".");
      this.$store
        .dispatch("product/updateProduct", { data: product, token: this.token })
        .then((res) => {
          let ind = this.$refs.productTable.data.findIndex(
            (i) => i.productId === this.editing.productId
          );
          this.$refs.productTable.data[ind].code = this.product.code;
          this.$refs.productTable.data[ind].name = this.product.name;
          this.$refs.productTable.data[ind].unit = this.product.unit;
          this.$refs.productTable.data[ind].unitPrice = this.product.unitPrice;
          this.$refs.productTable.data[
            ind
          ].taxPercent = this.product.taxPercent;
          this.editing = { index: null, productId: null };
        })
        .catch((error) => {});
      /*      .then(data => {
          if (data.success) {
            this.$notify({
              text: "Tietoja päivitetty",
              type: "success",
              duration: 5000
            });
          }
        })
        .catch(error => {
          this.$notify({
            text: "Päivityksessä tapahtui virhe",
            type: "error",
            duration: 5000
          });
        }); */
    },
    cancel: function () {
      this.editing = { index: null, productId: null };
    },
    favoriteProduct(product) {
      this.$store
        .dispatch("product/favoriteProduct", {
          req: {
            productId: product.productId,
            favorite: !product.isFavorite,
          },
          token: this.token,
        })
        .then((response) => {
          if (response.success) {
            product.isFavorite = !product.isFavorite;
          }
        });
    },
    deleteAction: function (products) {
      let ids = products.map((p) => p.productId);
      this.deletedProducts.push(...products);
      this.$store
        .dispatch("product/deleteProducts", {
          data: { ids: ids },
          token: this.token,
        })
        .then((response) => {
          if (response.success) {
            this.$refs.productTable.getData();
            this.selectedProducts = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.col-md-12 {
  padding: 0;
}
.favorite-icon {
  cursor: pointer;
}
.black {
  color: black;
}
.yellow {
  color: #f5cc27;
}
.gray {
  color: #c8c8c8;
}
.product-table {
  text-align: left;
}
.unit-price .edit-field {
  text-align: right;
}

.editable input {
  width: 100%;
  padding: 0;
  border-radius: 0;
  height: 1.8em;
  padding: 0 1.5em 0 0.1em;
}
.editable span {
  position: relative;
}
.editable select {
  width: 100%;
  height: 1.8em;
  background: white;
}
/* .edit-field > input:focus {
outline: none;
} */
.edit-field > select {
  width: 100%;
  height: 100%;
  box-shadow: none;
  background-color: white;
}

.edit-field > select:focus {
  outline: none;
}
.VueTables__sort-icon {
  font-size: 20px;
  float: right;
}

.product-action-cell {
  width: 4em;
}
.product-action-cell-icon {
  cursor: pointer;
  padding: 2px;
  font-size: 24px;
  width: 1em;
}

.product-action-cell-icon:hover {
  color: #007bff;
}
.code-col {
  width: 5em;
}

.unit-price {
  width: 8em !important;
}
.unit-col {
  width: 6rem;
}
.select-col {
  width: 1.5rem;
}
.action-col {
  text-align: center;
  width: 1em;
  border: none !important;
  background: white !important;
}

@media only screen and (max-width: 800px) {
  .unit-price .edit-field {
    text-align: left;
  }
  .code-col {
    width: 100%;
  }

  .unit-price {
    width: 100% !important;
  }
  .unit-col {
    width: 100%;
  }
  .select-col {
    width: 100%;
  }
  .product-flip-table .cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .product-flip-table * html .cf {
    zoom: 1;
  }
  .product-flip-table *:first-child + html .cf {
    zoom: 1;
  }

  .product-flip-table table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .product-flip-table th,
  .product-flip-table td {
    margin: 0;
    vertical-align: top;
  }
  .product-flip-table th {
    text-align: left;
    min-height: 40px;
  }

  .product-flip-table table {
    display: block;
    position: relative;
    width: 100%;
  }
  .product-flip-table thead {
    display: block;
    float: left;
    width: 5em;
  }
  .product-flip-table tbody {
    display: block;
    width: 70% !important;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }
  .product-flip-table thead tr {
    display: block;
    border-top: 1px solid #dee2e6;
  }
  .product-flip-table th {
    display: block;
    text-align: center;
    border: none;
  }
  .product-flip-table tbody tr {
    display: inline-block;
    vertical-align: top;
  }
  .product-flip-table td {
    display: block;
    min-height: 40px;
    text-align: left;
    width: 100%;
  }
  .product-flip-table td > input {
    height: 100%;
  }
}
@media only screen and (max-width: 426px) {
  .product-flip-table tbody {
    width: 300px;
  }
}
@media only screen and (max-width: 376px) {
  .product-flip-table tbody {
    width: 190px;
  }
}
/* FLIP END */
</style>

<style scoped>
.add-product {
  min-width: 7em;
}
.relative {
  position: relative;
}

#productActions .form-group {
  margin-bottom: 0;
}

#productActions .form-control {
}
.actions-opener {
  margin-top: 15px;
  border-bottom: 1px#ced4da solid;
}
.actions-opener a {
  font-size: 20px;
  text-decoration: none;
  color: #495057;
}
.actions-opener a:hover {
  color: gray;
}
.add-unit {
  box-shadow: none;
  padding: 0;
}

.search-field {
  position: relative;
}
.search-field > input {
  border-radius: 25px !important;
  box-shadow: 2px 4px 6px hsla(0, 0%, 0%, 0.2);
}
.search-icon {
  position: absolute;
  right: 14px;
  top: 7px;
  font-size: 25px;
}

.header-input {
}
.product-record-container {
  margin-top: 10px;
}
.product-record {
  padding: 1.5rem;
  border-radius: 5px;
  background-color: white;
}

td {
  padding: 0;
}
.selected-file {
  cursor: pointer;
}
/* .editable:focus-within {
  min-width: 12em;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
} */
.editable .edit-field:focus-within {
}
.input-group {
  width: 30%;
  margin: auto;
}
.editable {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.editable .edit-field {
}

@media screen and (max-width: 768px) {
  .product-record-container {
    margin-left: 0;
    margin-right: 0;
  }
  .input-group {
    width: 100%;
  }
}
</style>
