var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn btn-secondary",staticStyle:{"float":"right"},on:{"click":function($event){return _vm.$router.push({ name: 'invoiceBuilder' })}}},[_vm._v(" "+_vm._s(_vm.$t("product.back"))+" ")])]),_c('div',{staticClass:"product-record-container"},[_c('div',{staticClass:"product-record"},[_c('div',{staticClass:"input-group search-field"},[_c('input',{staticClass:"form-control form-control-lg",attrs:{"placeholder":_vm.$t('product.search')},domProps:{"value":_vm.searchText},on:{"input":function($event){return _vm.debounceSearch($event.target.value)}}}),_vm._m(0)]),_c('div',{staticClass:"product-flip-table mt-3"},[_c('v-server-table',{ref:"productTable",attrs:{"url":'',"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"code",fn:function(products){return [(products.row.productId === _vm.editing.productId)?_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.code),expression:"product.code"}],staticClass:"form-control",class:{
                  '': _vm.validations.product.code === null,
                  'is-valid': _vm.validations.product.code == true,
                  'is-invalid': _vm.validations.product.code == false,
                },domProps:{"value":(_vm.product.code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product, "code", $event.target.value)}}})]):_c('div',{staticClass:"edit-field"},[_vm._v(_vm._s(products.row.code))])]}},{key:"name",fn:function(products){return [(products.row.productId === _vm.editing.productId)?_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.name),expression:"product.name"}],staticClass:"form-control",class:{
                  '': _vm.validations.product.name === null,
                  'is-valid': _vm.validations.product.name == true,
                  'is-invalid': _vm.validations.product.name == false,
                },attrs:{"maxlength":"65"},domProps:{"value":(_vm.product.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product, "name", $event.target.value)}}})]):_c('div',{staticClass:"edit-field"},[_vm._v(_vm._s(products.row.name))])]}},{key:"unit",fn:function(products){return [(products.row.productId === _vm.editing.productId)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.unit),expression:"product.unit"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.product, "unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.unitTypes),function(unit,index){return _c('option',{key:index,domProps:{"value":unit}},[_vm._v(" "+_vm._s(_vm.$t("units." + unit))+" ")])}),0):_c('div',[_vm._v(_vm._s(_vm.$t("units." + products.row.unit)))])]}},{key:"unitPrice",fn:function(products){return [(products.row.productId === _vm.editing.productId)?_c('div',{staticClass:"relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.unitPrice),expression:"product.unitPrice"}],staticClass:"form-control",class:{
                  '': _vm.validations.product.unitPrice === null,
                  'is-valid': _vm.validations.product.unitPrice == true,
                  'is-invalid': _vm.validations.product.unitPrice == false,
                },domProps:{"value":(_vm.product.unitPrice)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.product, "unitPrice", $event.target.value)}}})]):_c('div',{staticClass:"edit-field"},[_vm._v(_vm._s(products.row.unitPrice))])]}},{key:"taxPercent",fn:function(products){return [(products.row.productId === _vm.editing.productId)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.product.taxPercent),expression:"product.taxPercent"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.product, "taxPercent", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.VATRates),function(rate,index){return _c('option',{key:index,domProps:{"value":rate}},[_vm._v(" "+_vm._s(_vm.formatNumber(rate))+" ")])}),0):_c('div',[_vm._v(_vm._s(_vm.formatNumber(products.row.taxPercent))+" %")])]}},{key:"action",fn:function(props){return [(props.row.productId === _vm.editing.productId)?_c('div',{staticClass:"product-action-cell"},[_c('i',{staticClass:"product-action-cell-icon far fa-save",attrs:{"title":_vm.$t('product.save')},on:{"click":_vm.update}}),_c('i',{staticClass:"product-action-cell-icon fas fa-ban",attrs:{"title":_vm.$t('product.cancel')},on:{"click":_vm.cancel}})]):_c('div',{staticClass:"product-action-cell"},[_c('i',{staticClass:"product-action-cell-icon far fa-edit",attrs:{"title":_vm.$t('product.edit')},on:{"click":function($event){return _vm.edit(props.index, props.row)}}}),_c('i',{staticClass:"product-action-cell-icon fas fa-trash-alt",attrs:{"title":_vm.$t('product.delete')},on:{"click":function($event){return _vm.deleteAction([props.row])}}})])]}},{key:"favorite",fn:function(props){return [_c('i',{class:{
                'fa fa-star favorite-icon yellow': props.row.isFavorite,
                'fa fa-star favorite-icon gray': !props.row.isFavorite,
              },attrs:{"title":_vm.$t('product.setFavorite')},on:{"click":function($event){return _vm.favoriteProduct(props.row)}}})]}}])})],1),_c('div',{attrs:{"id":"productActions"}},[_c('div',{staticClass:"row mt-3 mb-3 product-actions"},[_c('div',{staticClass:"col row"},[_c('div',{staticClass:"form-group col-lg col-xs-12 mr-2 mb-2"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t("product.code")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.newProduct.code),expression:"newProduct.code",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  '': _vm.validations.newProduct.code === null,
                  'is-valid': _vm.validations.newProduct.code === true,
                  'is-invalid': _vm.validations.newProduct.code === false,
                },attrs:{"placeholder":_vm.$t('product.code')},domProps:{"value":(_vm.newProduct.code)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.add.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newProduct, "code", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form-group col-lg col-xs-12 mr-2 mb-2"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t("product.name")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.newProduct.name),expression:"newProduct.name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  '': _vm.validations.newProduct.name === null,
                  'is-valid': _vm.validations.newProduct.name === true,
                  'is-invalid': _vm.validations.newProduct.name === false,
                },attrs:{"placeholder":_vm.$t('product.name')},domProps:{"value":(_vm.newProduct.name)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.add.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newProduct, "name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form-group col-lg mr-2 mb-2"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t("product.unit")))]),_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.newProduct.unit),expression:"newProduct.unit",modifiers:{"trim":true}}],staticClass:"form-control form-control-lg add-unit",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newProduct, "unit", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.unitTypes),function(unit,index){return _c('option',{key:index,domProps:{"value":unit}},[_vm._v(" "+_vm._s(_vm.$t("units." + unit))+" ")])}),0)]),_c('div',{staticClass:"form-group col-lg col-xs-12 mr-2 mb-2"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t("product.price")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.newProduct.unitPrice),expression:"newProduct.unitPrice",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                  '': _vm.validations.newProduct.unitPrice === null,
                  'is-valid': _vm.validations.newProduct.unitPrice === true,
                  'is-invalid': _vm.validations.newProduct.unitPrice === false,
                },attrs:{"placeholder":_vm.$t('product.price')},domProps:{"value":(_vm.newProduct.unitPrice)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.add.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.newProduct, "unitPrice", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form-group col-lg mr-2 mb-2"},[_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t("product.VAT")))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.newProduct.taxPercent),expression:"newProduct.taxPercent"}],staticClass:"form-control col-lg col-xs-12 form-control-lg add-unit",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.newProduct, "taxPercent", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.VATRates),function(item,index){return _c('option',{key:index,domProps:{"value":item}},[_vm._v(" "+_vm._s(_vm.formatNumber(item))+" ")])}),0)]),_c('div',{staticClass:"col"},[_c('button',{staticClass:"add-product btn btn-success mt-3 mr-2 mb-2 loading-btn",attrs:{"disabled":_vm.inserting},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.inserting ? "" : _vm.$t("product.add"))+" "),(_vm.inserting)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):_vm._e()])])]),_c('div',{staticClass:"col-lg-2 pl-0 mr-2 mb-2",staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"search-icon"},[_c('i',{staticClass:"fa fa-search text-muted"})])
}]

export { render, staticRenderFns }